import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppJob } from '../../../../../../_base-shared/models/App/AppJob';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { ReportService } from '../report.service';
import { RunOffExportComponent } from '../run-off-export/run-off-export.component';
import { PaymentReceivedModalComponent } from './payment-received-modal/payment-received-modal.component';
import { SalesReportModalComponent } from './sales-report-modal/sales-report-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector:    'app-financial-report-list',
  templateUrl: './financial-report-list.component.html',
  styles:      [],
})
export class FinancialReportListComponent implements OnInit {
  private authUser: User;
  public appJobs: Array<AppJob> = [];
  public isLoadingJobs          = 0;
  public generatingBreakageReport: boolean;

  public displayedColumns: string[] = [
    'name',
    'description',
    'actions',
  ];

  public reportOptions: Array<any> = [
    // {
    //   name:        this.translate.instant('REPORTS.financial-reports.list.breakage-report.title'),
    //   description: 'Generate Breakage Report',
    //   action:      this.generateBreakageReport.bind(this),
    //   disabled:    false,
    // },
    {
      name:        this.translate.instant('REPORTS.financial-reports.list.payment-received-report.title'),
      description: 'Generate Payment Received Report',
      action:      this.paymentReceivedModal.bind(this),
      disabled:    false,
    },
    // {
    //   name:        this.translate.instant('REPORTS.financial-reports.list.sales-report.title'),
    //   description: 'Generate Sales Report',
    //   action:      this.salesReportModal.bind(this),
    //   disabled:    false,
    // },
    // {
    //   name:        this.translate.instant('REPORTS.financial-reports.list.run-off-report.title'),
    //   description: 'Generate Run Off Report',
    //   action:      this.runOffReportModal.bind(this, 'Run Off Report', 'exportRunOffReports'),
    //   disabled:    false,
    // },
    {
      name:        this.translate.instant('REPORTS.financial-reports.list.client-creditor-report.title'),
      description: 'Generate Client Creditor Report',
      action:      this.runOffReportModal.bind(this, 'Client Creditor Report', 'clientCreditorReport'),
      disabled:    false,
    },
    {
      name:        this.translate.instant('REPORTS.financial-reports.list.win-amount-report.title'),
      description: 'Generate Win Amount Report',
      action:      this.runOffReportModal.bind(this, 'Win Amount Report', 'winAmountReport'),
      disabled:    false,
    },
  ]

  constructor(private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private reportService: ReportService,
              public dialog: MatDialog,
              private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    this.fetchQueuedJobs();
  }

  public generateBreakageReport() {
    const requestData             = {
      user_id: this.authUser.id,
    };
    this.generatingBreakageReport = true;
    this.reportService.generateBreakageReport(requestData)
        .pipe(finalize(() => this.generatingBreakageReport = false))
        .subscribe(
            result => {
              this.toastr.success(result.message);
              this.fetchQueuedJobs();
            },
        );
  }

  public fetchQueuedJobs() {
    this.isLoadingJobs++;
    this.reportService.indexQueuedExports({queue: 'export'}).pipe(finalize(() => this.isLoadingJobs--))
        .subscribe(
            result => {
              this.toastr.success(result.message ?? 'Queued jobs fetched successfully');
              this.appJobs = result.data;
            },
        );
  }

  public paymentReceivedModal($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(PaymentReceivedModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public salesReportModal($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(SalesReportModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public runOffReportModal(title: string, type: string) {
    this.dialog.open(RunOffExportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
      data:      {
        title, type
      },
    });
  }
}
