import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePaymentPlan } from '../../../../../../../../_base-shared/models/Payment/CasePaymentPlan';
import { CasePaymentPlanPhase } from '../../../../../../../../_base-shared/models/Product/PaymentPlanPhase';
import {
  PaymentPlanType,
  PaymentPlanTypeSlug,
} from '../../../../../../../../_base-shared/models/Product/PaymentPlanType';
import { PaymentPlanTypeService } from '../../../../payment/payment-plan-type.service';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CasePaymentPlanService } from '../../../case-payment-plan.service';

interface PlanGeneratorInputInterface {
  case: Case;
  isBulk: false;
  preselectedPlanType?: PaymentPlanTypeSlug;
  caseCreditorsIds?: Array<number>;
  debtPaymentPlan?: CasePaymentPlan;
  paymentPlanFilter?: string;
}

@Component({
  selector:    'app-case-payment-plan-generator',
  templateUrl: './case-payment-plan-generator.component.html',
  styles:      [],
})
export class CasePaymentPlanGeneratorComponent implements OnInit {
  @Input() case: Case;
  public generatedPaymentPlan: CasePaymentPlan;
  public isLoading                                   = 0;
  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public selectedPlanType: PaymentPlanType;
  public paymentPlanTypes: Array<PaymentPlanType>;
  public intervalUnitOptions: Array<AppSelectOption> = [];
  public startFromTypes: Array<AppSelectOption>      = [];
  public splitAmountTypes: Array<AppSelectOption>    = [];
  public caseCreditors: Array<CaseCreditor>          = [];
  public winTypes: Array<AppSelectOption>            = [];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PlanGeneratorInputInterface,
    @Optional() public dialogRef: MatDialogRef<CasePaymentPlanGeneratorComponent>,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
    private paymentPlanTypeService: PaymentPlanTypeService,
    private casePaymentPlanService: CasePaymentPlanService,
    private caseCreditorService: CaseCreditorService,
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.case) {
      this.case = this.data.case;
    }
    this.fetchPaymentPlanTypes();
    this.fetchCaseCreditors();
    this.buildIntervalUnitOptions();
    this.buildStartFromTypes();
    this.buildSplitAmountType();
    this.buildWinTypes();
  }

  get phasesArray(): FormArray<any> {
    return this.form.get('phases') as UntypedFormArray;
  }

  public submitForm(form: UntypedFormGroup): void {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    this.casePaymentPlanService.storePaymentPlan(this.case.id, form.getRawValue())
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        result => {
          this.generatedPaymentPlan = result.data;
          this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
            {model: 'Additional payment plan'}));
          this.dialogRef.close({dismissed: false, data: this.generatedPaymentPlan});
        },
        error => {
          this.serverResponse = error.error;
          this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
            {model: 'Additional payment plan'}));
        },
      );
  }

  public amountUpdated(newAmount: number, arrayIndex: number): void {
    if (this.phasesArray.at(arrayIndex).get('split_amount_type').value === 'with_remainder') {
      this.phasesArray.at(arrayIndex).get('installments_count').patchValue(
        Math.ceil(newAmount / +this.phasesArray.at(arrayIndex).get('installment_amount').value),
        {onlySelf: true, emitEvent: false},
      );
      this.phasesArray.at(arrayIndex).get('installments_count').updateValueAndValidity();
      this.patchIntervalValidators(this.phasesArray.at(arrayIndex).get('installments_count').value > 1, arrayIndex);
    }
  }

  public installmentAmountChanged(installmentAmount: number, arrayIndex: number): void {
    if (this.phasesArray.at(arrayIndex).get('split_amount_type').value === 'equally') {
      this.phasesArray.at(arrayIndex).get('amount').patchValue(
        installmentAmount * (+this.phasesArray.at(arrayIndex).get('installments_count').value),
        {onlySelf: true, emitEvent: false},
      );
    } else {
      this.phasesArray.at(arrayIndex).get('installments_count').patchValue(
        Math.ceil(+this.phasesArray.at(arrayIndex).get('amount').value / installmentAmount),
        {onlySelf: true, emitEvent: false},
      );
    }

    this.patchIntervalValidators(this.phasesArray.at(arrayIndex).get('installments_count').value > 1, arrayIndex);
  }

  public installmentCountChanged(installmentsCount: number, arrayIndex: number): void {
    if (this.phasesArray.at(arrayIndex).get('split_amount_type').value === 'equally') {
      this.phasesArray.at(arrayIndex).get('amount').patchValue(
        (+this.phasesArray.at(arrayIndex).get('installment_amount').value) * installmentsCount,
        {onlySelf: true, emitEvent: false},
      );
    }

    this.winAmountChanged();

    this.patchIntervalValidators(installmentsCount > 1, arrayIndex);
  }

  private patchIntervalValidators(multipleInstallments: boolean, arrayIndex: number): void {
    if (multipleInstallments) {
      this.phasesArray.at(arrayIndex).get('interval').setValidators([Validators.required, Validators.min(1)]);
      this.phasesArray.at(arrayIndex).get('interval_unit').setValidators([Validators.required]);
    } else {
      this.phasesArray.at(arrayIndex).get('interval').setValidators([]);
      this.phasesArray.at(arrayIndex).get('interval_unit').setValidators([]);
    }
    this.phasesArray.at(arrayIndex).get('interval').updateValueAndValidity();
    this.phasesArray.at(arrayIndex).get('interval_unit').updateValueAndValidity();
  }

  public addPaymentPhase(): void {
    const phasesArray = this.form.get('phases') as UntypedFormArray;
    phasesArray.push(this.initPaymentPhaseGroup(null));
  }

  private buildForm(): void {
    this.form = this.fb.group({
      payment_plan_type_id: [
        {
          value:    this.selectedPlanType?.id,
          disabled: this.selectedPlanType?.id,
        }, [Validators.required],
      ],
      case_creditor_ids:    [
        this.data.caseCreditorsIds?.length ? this.data.caseCreditorsIds : [],
      ],
      win_type:             [null, this.selectedPlanType?.slug === 'claim_fee' ? [Validators.required] : []],
      win_amount:           [null, this.selectedPlanType?.slug === 'claim_fee' ? [Validators.required] : []],
      phases:               this.fb.array([
        this.initPaymentPhaseGroup(this.initDefaultPaymentPhase()),
      ]),
    });
  }

  private buildIntervalUnitOptions(): void {
    this.intervalUnitOptions = [
      {
        value: 'day',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.day'),
      },
      {
        value: 'week',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.week'),
      },
      {
        value: 'month',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.month'),
      },
    ];
  }

  private buildSplitAmountType(): void {
    this.splitAmountTypes = [
      {
        value: 'with_remainder',
        label: this.translate.instant(
          'CASES.single.payments.case_payment_plan_generator.form.split_amount_type.options.with_remainder',
        ),
      },
      {
        value: 'equally',
        label: this.translate.instant(
          'CASES.single.payments.case_payment_plan_generator.form.split_amount_type.options.equally',
        ),
      },
    ];
  }

  private buildStartFromTypes(): void {
    this.startFromTypes = [
      {
        value: 'date',
        label: this.translate.instant(
          'CASES.single.payments.case_payment_plan_generator.form.start_from_type.options.date',
        ),
      },
      {
        value: 'end_of_all_installments',
        label: this.translate.instant(
          'CASES.single.payments.case_payment_plan_generator.form.start_from_type.options.end_of_all_installments',
        ),
      },
    ];
  }

  private fetchPaymentPlanTypes(): void {
    this.isLoading++;

    this.paymentPlanTypeService.index().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.paymentPlanTypes = result.data;
        // if (this.data.preselectedPlanType) {
        //   this.selectedPlanType = this.paymentPlanTypes.find(
        //     planType => planType.slug === this.data.preselectedPlanType);
        // }
        this.buildForm();
      });
  }

  private fetchCaseCreditors(): void {
    this.isLoading++;
    this.caseCreditorService.indexCaseCreditors(this.case.id, ['creditor'], {select_all: true}).pipe(finalize(() => this.isLoading--))
      .subscribe(caseCreditorsResult => {
        this.caseCreditors = caseCreditorsResult.data;
        this.caseCreditors.map(creditor => {
          creditor.new_reference = creditor.reference_number + ' — ' + creditor.creditor.name;
        });
      });
  }

  private initDefaultPaymentPhase(): CasePaymentPlanPhase {
    const paymentPhase              = new CasePaymentPlanPhase();
    paymentPhase.start_from         = new Date();
    paymentPhase.split_amount_type  = 'equally';
    paymentPhase.amount             = 1;
    paymentPhase.installment_amount = 1;
    paymentPhase.installments_count = 1;
    paymentPhase.interval_unit      = 'month';

    return paymentPhase;
  }

  private initPaymentPhaseGroup(paymentPhase: CasePaymentPlanPhase) {
    return this.fb.group({
      start_from_type:    ['date'],
      start_from:         [paymentPhase.start_from, [Validators.required]],
      amount:             [
        {value: paymentPhase.amount, disabled: paymentPhase.split_amount_type === 'equally'},
        [Validators.required, Validators.min(1)],
      ],
      split_amount_type:  [paymentPhase.split_amount_type, [Validators.required]],
      installment_amount: [
        {value: paymentPhase.installment_amount},
        [Validators.required, Validators.min(1)],
      ],
      installments_count: [
        {
          value:    paymentPhase.installments_count,
          disabled: paymentPhase.split_amount_type === 'with_remainder',
        }, [Validators.required, Validators.min(1)],
      ],
      interval:           [paymentPhase.interval],
      interval_unit:      [paymentPhase.interval_unit],
    });
  }

  public winAmountChanged(amount?: number): void {
    if (this.selectedPlanType.slug !== 'claim_fee') {
      return;
    }

    const winAmount         = amount ? amount : this.form.get('win_amount').value;
    const totalAmount       = +((winAmount * ((this.data.debtPaymentPlan.percentage_agreed / 100) +
      ((this.data.debtPaymentPlan.percentage_agreed / 100) * 0.21))).toFixed(2));
    const installmentAmount = totalAmount / +this.phasesArray.at(0).get('installments_count').value;
    this.phasesArray.at(0).get('amount').patchValue(
      totalAmount,
      {onlySelf: true, emitEvent: false},
    );
    this.phasesArray.at(0).get('installment_amount').patchValue(
      installmentAmount,
      {onlySelf: true, emitEvent: false},
    );
  }

  public splitTypeChanged(splitAmountType: 'equally' | 'with_remainder', index: number): void {
    if (splitAmountType === 'equally') {
      this.phasesArray.at(index).get('installments_count').enable();
      this.phasesArray.at(index).get('amount').disable();

      this.phasesArray.at(index).get('installments_count').setValidators([Validators.required, Validators.min(1)]);
      this.phasesArray.at(index).get('amount').clearValidators();
    } else {
      this.phasesArray.at(index).get('amount').enable();
      this.phasesArray.at(index).get('installments_count').disable();

      this.phasesArray.at(index).get('amount').setValidators([Validators.required, Validators.min(1)]);
      this.phasesArray.at(index).get('installments_count').clearValidators();
    }

    this.phasesArray.at(index).get('amount').updateValueAndValidity();
    this.phasesArray.at(index).get('installments_count').updateValueAndValidity();
  }

  public planTypeChanged(typeId: any): void {
    this.selectedPlanType = this.paymentPlanTypes.find(planType => planType.id === typeId);
    if (this.selectedPlanType.slug === 'claim_fee') {
      this.form.get('win_type').enable();
      this.form.get('win_amount').enable();
      this.phasesArray.at(0).get('installment_amount').disable();
      this.form.get('win_type').setValidators([Validators.required]);
      this.form.get('win_amount').setValidators([Validators.required]);
      this.form.get('win_type').updateValueAndValidity();
      this.form.get('win_amount').updateValueAndValidity();
    } else {
      this.form.get('win_type').disable();
      this.form.get('win_amount').disable();
      this.phasesArray.at(0).get('installment_amount').enable();
      this.form.get('win_type').setValidators([]);
      this.form.get('win_amount').setValidators([]);
      this.form.get('win_type').updateValueAndValidity();
      this.form.get('win_amount').updateValueAndValidity();
    }

    if (this.selectedPlanType.custom) {
      this.form.get('case_creditor_ids').disable();
      this.form.get('case_creditor_ids').setValidators([]);
      this.form.get('case_creditor_ids').updateValueAndValidity();
    } else {
      this.form.get('case_creditor_ids').enable();
      this.form.get('case_creditor_ids').setValidators([]);
      this.form.get('case_creditor_ids').updateValueAndValidity();
    }
  }

  public buildWinTypes(): void {
    this.winTypes = [
      {
        label: this.translate.instant('CASES.single.payments.case_payment_plan_generator.form.win_type.options.offset'),
        value: 'offset',
      },
      {
        label: this.translate.instant('CASES.single.payments.case_payment_plan_generator.form.win_type.options.cash'),
        value: 'cash',
      },
      {
        label: this.translate.instant('CASES.single.payments.case_payment_plan_generator.form.win_type.options.mixed'),
        value: 'mixed',
      },
    ];
  }
}
