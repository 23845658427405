<nav mat-tab-nav-bar>
    <a mat-tab-link routerLink="cases" [active]="isActive('cases')">
      {{ "CASES.dashboard.tabs.cases" | translate }}
    </a>
  <a mat-tab-link routerLink="claims" [active]="isActive('claims')">
    {{ "CASES.dashboard.tabs.claims" | translate }}
  </a>
  <a mat-tab-link routerLink="tasks" [active]="isActive('tasks')">
    {{ "CASES.dashboard.tabs.tasks" | translate }}&nbsp;
    <span *ngIf="overdueTasks > 0" class="badge badge-danger">{{ overdueTasks }}</span>
  </a>
  <a mat-tab-link routerLink="notifications" [active]="isActive('notifications')">
    {{ "CASES.dashboard.tabs.notifications" | translate }}&nbsp;
  </a>
</nav>
<div class="mt-2">
  <router-outlet></router-outlet>
</div>
