import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { TownHall } from '../../../../../../../_base-shared/models/Entity/TownHall';
import { CaseCreditorProduct } from '../../../../../../../_base-shared/models/Product/CaseCreditorProduct';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { CreditorService } from '../../../creditor/creditor.service';
import { TownHallService } from '../../../legal-entity/town-hall.service';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { UserService } from '../../../user/user.service';
import { CaseCreditorService } from '../../case-creditor.service';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-creditor-editor',
  templateUrl: './case-creditor-editor.component.html',
  styleUrls:   ['./case-creditor-editor.component.scss'],
})
export class CaseCreditorEditorComponent implements OnInit {
  public case: Case;
  public isLoading                                        = 0;
  public isSubmitting: boolean;
  public claimCreditors: Array<CaseCreditor>;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public townHalls: Array<TownHall>                       = [];
  public singleOwnershipOptions: Array<AppSelectOption>   = [];
  public jointOwnershipOptions: Array<AppSelectOption>    = [];
  public debtTypes: Array<AppSelectOption>                = [];
  public caseCreditorProducts: Array<CaseCreditorProduct> = [];
  public creditors: Array<Creditor>                       = [];

  public totalDebt           = 0;
  public totalMonthlyPayment = 0;
  public authUser: User;

  public insurance: Array<AppSelectOption>            = [];
  public insuranceTypes: Array<AppSelectOption>       = [];
  public mortgageTypes: Array<AppSelectOption>        = [];
  public mortgageCurrencies: Array<AppSelectOption>   = [];
  public mortgageClause: Array<AppSelectOption>       = [];
  public mortgageExpensesPaid: Array<AppSelectOption> = [];
  public suggestedProducts                            = [];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventService: MainGlobalEventService,
    private userService: UserService,
    private caseService: CaseService,
    private creditorService: CreditorService,
    private caseCreditorService: CaseCreditorService,
    private financialOverviewService: FinancialOverviewService,
    private townHallService: TownHallService,
  ) {
  }

  ngOnInit(): void {
    this.buildSelectOptions();
    this.buildInsuranceTypes();
    this.buildClauseTypes();
    this.buildMortgageExpansesTypes();
    this.buildTypes();
    this.buildMortgageTypes();
    this.buildCurrencyTypes();
    this.fetchCreditors();
    this.fetchCaseCreditorProducts();
    this.fetchTownHalls(null);
    this.translate.onLangChange.subscribe(() => this.buildSelectOptions());
    this.globalEventService.authUser$.subscribe(user => {
      this.authUser = user;
      this.getAuthUser(user.id);
    });
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId, ['product'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case = result.data;
          this.isLoading++;
          this.caseCreditorService.indexCaseCreditors(this.case.id, ['creditor'], {select_all: true})
            .pipe(finalize(() => this.isLoading--))
            .subscribe(res => {
              this.claimCreditors = res.data;
              this.buildForm(this.claimCreditors);
              this.form.getRawValue().claim_creditors.map((creditor, i) => {
                this.suggestedProducts.push({index: i, product_suggestion: ' '});
                if (creditor.insurance) {
                  this.suggestedProducts[i] = {...this.suggestedProducts[i], product_suggestion: 'PPI' + ', '};
                }
                if (creditor.debt_type === 'card' || 'microlender') {
                  this.suggestedProducts[i] = {
                    ...this.suggestedProducts[i],
                    product_suggestion: this.suggestedProducts[i].product_suggestion.concat(
                      'Intereses abusivos' + ', '),
                  };
                }
                if (creditor.debt_type === 'mortgage' && creditor.insurance_type === 'life') {
                  this.suggestedProducts[i] = {
                    ...this.suggestedProducts[i],
                    product_suggestion: this.suggestedProducts[i].product_suggestion.replace('H.Seguro de vida', ', '),
                  };
                }
                if (creditor.mortgage_currency === 'yenes' || creditor.mortgage_currency === 'francos_suizos') {
                  this.suggestedProducts[i] = {
                    ...this.suggestedProducts[i],
                    product_suggestion: this.suggestedProducts[i].product_suggestion.concat('H.Multidivisa' + ', '),
                  };
                }
                if (creditor.mortgage_clause) {
                  this.suggestedProducts[i] = {
                    ...this.suggestedProducts[i],
                    product_suggestion: this.suggestedProducts[i].product_suggestion.concat(
                      'H.Clausula suelo' + 'H.Clausula suelo + H.Gastos' + ', '),
                  };
                }
                if (creditor.mortgage_type === 'variable' || creditor.mortgage_type === 'mixed') {
                  if (!this.suggestedProducts[i].product_suggestion.includes('H.Clausula suelo + H.Gastos')) {
                    this.suggestedProducts[i] = {
                      ...this.suggestedProducts[i],
                      product_suggestion: this.suggestedProducts[i].product_suggestion.concat(
                        'H.Clausula suelo + H.Gastos' + ', '),
                    };
                  }
                  if (!this.suggestedProducts[i].product_suggestion.includes('H.Clausula suelo')) {
                    this.suggestedProducts[i] = {
                      ...this.suggestedProducts[i],
                      product_suggestion: this.suggestedProducts[i].product_suggestion.concat(
                        'H.Clausula suelo' + ', '),
                    };
                  }
                }
                if (creditor.mortgage_expenses_paid) {
                  this.suggestedProducts[i] = {
                    ...this.suggestedProducts[i],
                    product_suggestion: this.suggestedProducts[i].product_suggestion.concat('H.Gastos' + ', '),
                  };
                }
                this.suggestedProducts.map(suggestion => {
                  if (suggestion.product_suggestion === '') {
                    this.suggestedProducts.push({index: i, product_suggestion: 'N/A '});
                  }
                });
              });
              this.form.valueChanges.subscribe((change) => {
                if (this.suggestedProducts.length < change.claim_creditors.length) {
                  this.suggestedProducts.push({index: change.claim_creditors.length - 1, product_suggestion: 'N/A'});
                }
              });
            });
        });
    });
  }

  public suggestProduct(e, i, inputName: string): void {
    const control           = this.form.controls.claim_creditors as FormArray;
    const productSuggestion = this.suggestedProducts[i].product_suggestion;

    // Helper function to add a suggestion
    const addSuggestion = (suggestion: string) => {
      this.suggestedProducts[i] = {
        ...this.suggestedProducts[i],
        product_suggestion: productSuggestion.concat(suggestion + ', '),
      };
    };

    // Helper function to remove a suggestion
    const removeSuggestion = (suggestion: string) => {
      this.suggestedProducts[i] = {
        ...this.suggestedProducts[i],
        product_suggestion: productSuggestion.replace(suggestion, ' '),
      };
    };

    // Initialize hasHClausulaSuelo and hasHClausulaSueloAndGastos variables
    let hasHClausulaSuelo          = false;
    let hasHClausulaSueloAndGastos = false;

    switch (inputName) {
      case 'insurance':
        if (e) {
          addSuggestion('PPI');
        } else if (productSuggestion.includes('PPI')) {
          removeSuggestion('PPI,');
        }
        break;
      case 'debt_type':
        hasHClausulaSuelo          = productSuggestion.includes('H.Clausula suelo');
        hasHClausulaSueloAndGastos = productSuggestion.includes('H.Clausula suelo + H.Gastos');
        if (hasHClausulaSuelo || hasHClausulaSueloAndGastos || productSuggestion.includes('H.Multidivisa') ||
          productSuggestion.includes('H.Gastos') || productSuggestion.includes('H.Clausula suelo + H.Gastos')) {
          removeSuggestion('H.Clausula suelo,');
          removeSuggestion('H.Clausula suelo + H.Gastos,');
          removeSuggestion('H.Gastos,');
          removeSuggestion('H.Clausula suelo + H.Gastos,');
          removeSuggestion('H.Multidivisa,');
          removeSuggestion('H.Clausula suelo + H.Gastos,');
        }
        if (e === 'card' || e === 'microlender') {
          if (!productSuggestion.includes('Intereses abusivos')) {
            addSuggestion('Intereses abusivos');
          }
        } else if (productSuggestion.includes('Intereses abusivos,')) {
          removeSuggestion('Intereses abusivos,');
        }
        if (e === 'mortgage' && control.controls[i].get('insurance_type').value === 'life') {
          addSuggestion('H.Seguro de vida,');
        } else if (productSuggestion.includes('H.Seguro de vida')) {
          removeSuggestion('H.Seguro de vida,');
        }
        break;
      case 'mortgage_currency':
        if (control.controls[i].get('debt_type').value === 'mortgage' && (e === 'yenes' || e === 'francos_suizos')) {
          if (!productSuggestion.includes('H.Multidivisa')) {
            addSuggestion('H.Multidivisa');
          }
        } else if (productSuggestion.includes('H.Multidivisa')) {
          removeSuggestion('H.Multidivisa,');
        }
        break;
      case 'mortgage_clause':
        hasHClausulaSuelo          = productSuggestion.includes('H.Clausula suelo');
        hasHClausulaSueloAndGastos = productSuggestion.includes('H.Clausula suelo + H.Gastos');
       /* if (e && control.controls[i].get('mortgage_expenses_paid').value) {
        addSuggestion('H.Clausula suelo + H.Gastos');
      }*/
        if (e) {
          if (control.controls[i].get('mortgage_expenses_paid').value){
            addSuggestion('H.Clausula suelo + H.Gastos');
            break;
          }
          if (!hasHClausulaSuelo) {
            addSuggestion('H.Clausula suelo');
          }
        /*  if (!hasHClausulaSueloAndGastos) {
            console.log(hasHClausulaSuelo, hasHClausulaSueloAndGastos);
            addSuggestion('H.Clausula suelo + H.Gastos');
          }*/
        }  else {
          if (hasHClausulaSuelo && (control.controls[i].get('mortgage_type').value !== 'variable' ||
            control.controls[i].get('mortgage_type').value !== 'mixed')) {
            removeSuggestion('H.Clausula suelo,');
          }
          if (hasHClausulaSueloAndGastos && (control.controls[i].get('mortgage_type').value !== 'variable' ||
            control.controls[i].get('mortgage_type').value !== 'mixed')) {
            removeSuggestion('H.Clausula suelo + H.Gastos,');
          }
        }
        break;
      case 'mortgage_type':
        hasHClausulaSuelo          = productSuggestion.includes('H.Clausula suelo');
        hasHClausulaSueloAndGastos = productSuggestion.includes('H.Clausula suelo + H.Gastos');
        if (e === 'variable' || e === 'mixed') {
          if (!hasHClausulaSuelo) {
            addSuggestion('H.Clausula suelo');
          }
          if (!hasHClausulaSueloAndGastos  && control.controls[i].get('mortgage_expenses_paid').value) {
            addSuggestion('H.Clausula suelo + H.Gastos');
          }
        } else {
          if (hasHClausulaSuelo && !control.controls[i].get('mortgage_clause').value === true) {
            removeSuggestion('H.Clausula suelo,');
          }
          if (hasHClausulaSueloAndGastos && !control.controls[i].get('mortgage_clause').value === true) {
            removeSuggestion('H.Clausula suelo + H.Gastos,');
          }
        }
        break;
      case 'mortgage_expenses_paid':
        if (e && control.controls[i].get('mortgage_clause').value){
          addSuggestion('H.Clausula suelo + H.Gastos');
          break;
        }
        if (e) {
          addSuggestion('H.Gastos');
          if (control.controls[i].get('mortgage_type').value === 'variable'|| control.controls[i].get('mortgage_type').value === 'mixed') {
            addSuggestion('H.Clausula suelo + H.Gastos');
          }
         /* addSuggestion('H.Clausula suelo + H.Gastos');*/
        } else {
          if (productSuggestion.includes('H.Gastos')) {
            removeSuggestion('H.Gastos,');
          }
          if (productSuggestion.includes('H.Clausula suelo + H.Gastos,')) {
            removeSuggestion('H.Clausula suelo + H.Gastos,');
          }
        }
        break;
      case 'insurance_type':
        if (e === 'life' && control.controls[i].get('debt_type').value === 'mortgage') {
          if (!productSuggestion.includes('H.Seguro de vida')) {
            addSuggestion('H.Seguro de vida');
          }
        } else if (productSuggestion.includes('H.Seguro de vida')) {
          removeSuggestion('H.Seguro de vida,');
        }
        break;
    }
  }

  public submitForm(form: UntypedFormGroup): void {
    this.checkIfTotalRepaidIsRequired('claim_creditors');
    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    const formData    = form.getRawValue();
    formData.claim_creditors.forEach(creditor => {
      if (creditor.loan_start_date) {
        const date = new Date(creditor.loan_start_date);
        date.setDate(date.getDate() + 1);
        creditor.loan_start_date = date.toISOString().split('T')[0];
      }
    });
    this.caseCreditorService.upsert(this.case.id, formData)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        () => {
          this.form.markAsPristine();
          this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
        },
        error => {
          this.serverResponse = error.error;
          this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
        },
      );
  }

  public isComponentDirty(): boolean {
    return this.form.dirty;
  }

  public isComponentValid(): boolean {
    return this.form.valid;
  }

  public submitComponent() {
    return this.submitForm(this.form);
  }

  private updateTotalDebt(updateFinancialOverview = true) {
    this.totalDebt           = 0;
    this.totalMonthlyPayment = 0;

    this.getFormArray('claim_creditors').controls.forEach(control => {
      this.totalDebt += +control.value.current_balance;
      this.totalMonthlyPayment += +control.value.monthly_payments;
    });
    if (updateFinancialOverview) {
      this.financialOverviewService.financialOverview$.pipe(take(1)).subscribe(overview => {
        if (overview) {
          overview.total_debt      = this.totalDebt;
          overview.monthly_payment = this.totalMonthlyPayment;
          this.financialOverviewService.updateFinancialOverviewLocally(overview);
        }
      });
    }
  }

  private getAuthUser(userId: number) {
    this.isLoading++;
    this.userService.get(userId, {}, ['packager']).pipe(finalize(() => this.isLoading--)).subscribe(user => {
      this.authUser = user.data;
    });
  }

  private buildForm(caseCreditors: Array<CaseCreditor>) {
    this.form = this.fb.group({
      claim_creditors: this.fb.array([]),
    });

    this.addCreditorsToForm(caseCreditors);

    this.updateTotalDebt();

    this.form.get('claim_creditors').valueChanges.subscribe(() => this.updateTotalDebt());
  }

  private addCreditorsToForm(caseCreditors: Array<CaseCreditor>): void {
    if (caseCreditors?.length > 0) {
      caseCreditors.forEach(caseCreditor => {
        this.addCreditor(caseCreditor);
      });
    }
    this.form.get('claim_creditors').valueChanges.subscribe((resp) => {
      resp.forEach(res => {
        if (res.lender_type === 'high_interest') {
          const creditorForm = this.form.get('claim_creditors') as FormArray;
          creditorForm.at(resp.length - 1).get('total_repaid').addValidators([Validators.required]);
          this.form.updateValueAndValidity();
        }
      });
    });
  }

  public checkCreditors($event, type: 'claim_creditors', index: number) {
    const selectedCreditor    = this.creditors.find(creditor => creditor.id === $event);
    const approvedFormControl = this.getFormArray(type).at(index).get('approved');
    if (approvedFormControl.value !== selectedCreditor.approved) {
      approvedFormControl.patchValue(selectedCreditor.approved);
      approvedFormControl.updateValueAndValidity();
    }
    const lenderTypeFormControl = this.getFormArray(type).at(index).get('lender_type');
    if (lenderTypeFormControl.value !== selectedCreditor.lender_type) {
      lenderTypeFormControl.patchValue(selectedCreditor.lender_type);
      lenderTypeFormControl.updateValueAndValidity();
    }
    const creditorForm = this.form.get(type) as FormArray;
    creditorForm.at(index).get('creditor_id')?.valueChanges.subscribe((res) => {
      if (creditorForm.at(index).value.lender_type === 'high_interest') {
        creditorForm.at(index).get('total_repaid').addValidators([Validators.required]);
      }
    });
  }

  public getFormArray(creditorType: 'claim_creditors') {
    return this.form.get(creditorType) as UntypedFormArray;
  }

  public addCreditor(caseCreditor: CaseCreditor = null) {
    caseCreditor    = caseCreditor ? caseCreditor : this.getDefaultCaseCreditor();
    const formArray = this.getFormArray('claim_creditors');
    const formGroup = this.fb.group({
      approved:                 [{value: caseCreditor.creditor.approved, disabled: true}],
      lender_type:              [{value: caseCreditor.creditor.lender_type, disabled: true}],
      id:                       [caseCreditor.id],
      verified:                 [caseCreditor.verified, null],
      case_id:                  [caseCreditor.case_id],
      creditor_id:              [caseCreditor.creditor_id, [Validators.required]],
      case_creditor_product_id: [caseCreditor.case_creditor_product_id, null],
      type:                     ['claim', [Validators.required]],
      ownership:                [
        {value: caseCreditor.ownership, disabled: !this.case.joint_application},
        [Validators.required],
      ],
      debt_type:                [caseCreditor.debt_type, null],
      initial_balance:          [caseCreditor.initial_balance, null],
      current_balance:          [caseCreditor.current_balance, null],
      monthly_payments:         [caseCreditor.monthly_payments, null],
      total_repaid:             [
        caseCreditor.total_repaid,
        caseCreditor.lender_type === 'high_interest' ? [Validators.required] : [],
      ],
      making_payments:          [caseCreditor.making_payments, null],
      contract_number:          [caseCreditor.contract_number, null],
      year_of_hire:             [caseCreditor.year_of_hire, null],
      interest:                 [caseCreditor.interest, null],
      insurance:                [caseCreditor.insurance, null],
      insurance_type:           [caseCreditor.insurance_type, null],
      insurance_amount_paid:    [caseCreditor.insurance_amount_paid, null],
      mortgage_type:            [caseCreditor.mortgage_type, null],
      mortgage_expenses_paid:   [caseCreditor.mortgage_expenses_paid, null],
      mortgage_currency:        [caseCreditor.mortgage_currency, null],
      mortgage_clause:          [caseCreditor.mortgage_clause, null],
      loan_start_date:          [caseCreditor.loan_start_date, null],
    });
    formArray.push(formGroup);
  }

  public removeCreditor(creditorType: 'claim_creditors',
                        index: number) {
    const formArray = this.getFormArray(creditorType);
    formArray.removeAt(index);
  }

  private fetchCreditors(): void {
    this.isLoading++;
    this.creditorService.index({all: 1, active: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
          this.creditors = result.data;
          // this.checkCreditors();
        },
        err => console.error(err),
      );
  }

  private fetchCaseCreditorProducts(): void {
    this.isLoading++;
    this.creditorService.getCaseCreditorProducts().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.caseCreditorProducts = result.data;
      });
  }

  private buildSelectOptions(): void {
    this.singleOwnershipOptions = [
      {value: 'applicant', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.applicant')},
    ];

    this.jointOwnershipOptions = [
      {
        value: 'applicant',
        label: this.translate.instant('CASE_CREDITOR.model.ownership.options.applicant_joint'),
      },
      {value: 'partner', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.partner')},
      {value: 'joint', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.joint')},
    ];

    this.debtTypes = [
      {value: 'personal-loan', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.personal-loan')},
      {value: 'card', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.card')},
      {value: 'car-finance', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.car-finance')},
      {value: 'mortgage', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.mortgage')},
      // {value: 'domain-reservation', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.domain-reservation')},
      // {value: 'endorsement', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.endorsement')},
      // {value: 'guarantor', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.guarantor')},
      // {value: 'other', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.other')},
      // {value: 'loan', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.loan')},
      // {value: 'tax', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.tax')},
      // {value: 'overdraft', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.overdraft')},
      // {value: 'microlender', label: this.translate.instant('Microlender')},
    ];
  }

  private buildInsuranceTypes(): void {
    this.insurance = [
      {value: true, label: this.translate.instant('SHARED.yes')},
      {value: false, label: this.translate.instant('SHARED.no')},
    ];
  }

  private buildClauseTypes(): void {
    this.mortgageClause = [
      {value: true, label: this.translate.instant('SHARED.yes')},
      {value: false, label: this.translate.instant('SHARED.no')},
    ];
  }

  private buildMortgageExpansesTypes(): void {
    this.mortgageExpensesPaid = [
      {value: true, label: this.translate.instant('SHARED.yes')},
      {value: false, label: this.translate.instant('SHARED.no')},
    ];
  }

  private buildTypes(): void {
    this.insuranceTypes = [
      {value: 'life', label: this.translate.instant('CASE_CREDITOR.model.type.options.life')},
      {
        value: 'payment_protection',
        label: this.translate.instant('CASE_CREDITOR.model.type.options.payment_protection'),
      },
    ];
  }

  private buildMortgageTypes(): void {
    this.mortgageTypes = [
      {value: 'fix', label: this.translate.instant('CASE_CREDITOR.model.type_of_mortgage.options.fix')},
      {value: 'variable', label: this.translate.instant('CASE_CREDITOR.model.type_of_mortgage.options.variable')},
      {value: 'mixed', label: this.translate.instant('CASE_CREDITOR.model.type_of_mortgage.options.mixed')},
    ];
  }

  private buildCurrencyTypes(): void {
    this.mortgageCurrencies = [
      {value: 'euros', label: this.translate.instant('CASE_CREDITOR.model.currency.options.euros')},
      {value: 'yenes', label: this.translate.instant('CASE_CREDITOR.model.currency.options.yen')},
      {value: 'francos_suizos', label: this.translate.instant('CASE_CREDITOR.model.currency.options.swiss_francs')},
    ];
  }

  public fetchTownHalls(search: string) {
    this.isLoading++;
    this.townHallService.index({select_all: 1, search}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.townHalls = result.data,
      error => console.error(error),
    );
  }

  private getDefaultCaseCreditor(): CaseCreditor {
    const caseCreditor             = new CaseCreditor();
    caseCreditor.creditor          = new Creditor();
    caseCreditor.creditor.approved = 0;
    caseCreditor.ownership         = 'applicant';
    caseCreditor.type              = 'claim';

    return caseCreditor;
  }

  public getOutcome(formGroup) {
    const initialBalance = formGroup.get('initial_balance').value;
    const totalRepaid    = formGroup.get('total_repaid').value;
    if (initialBalance === null || totalRepaid === null) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.undetermined');
    }
    if (initialBalance === totalRepaid) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.cancel_debt');
    }
    if (initialBalance > totalRepaid) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.pay_difference');
    }
    if (initialBalance < totalRepaid) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.payback');
    }
  }

  public getAmountToCondone(amount: number, currentBalance: number) {
    return amount > 0 ? currentBalance - amount : currentBalance;
  }

  private checkIfTotalRepaidIsRequired(formType: 'claim_creditors'): any {
    const numberOfCreditors = this.form.get(formType).value.length;
    for (let i = 0; i < numberOfCreditors - 1; i++) {
      const creditorsArray = this.form.get(formType) as FormArray;
      if (creditorsArray.at(i).get('total_repaid').hasError('required')) {
        /* this.toastr.error(this.translate.instant('CASE_CREDITOR.errors.error_total_repaid'));*/
        return Swal.fire({
          title:              this.translate.instant('CASE_CREDITOR.errors.error_total_repaid') + '!',
          icon:               'error',
          customClass:        {
            title: 'error-txt',
          },
          showConfirmButton:  false,
          showCancelButton:   true,
          cancelButtonText:   this.translate.instant('SHARED.close'),
          confirmButtonColor: '#4267b2',
        });
      }
    }
  }

  public clearFormControl($event, name) {
    $event.preventDefault();
    $event.stopPropagation();
    this.form.get(name).patchValue(null);
  }
}
