<h1 mat-dialog-title>{{ "CREDITORS.reassign-title" | translate }}</h1>
<div class="pt-3">
  <div *ngIf="isLoading" class="row">
    <div class="col-12 pt-5 text-center">
      <mat-spinner color="primary" class="m-0 m-auto"></mat-spinner>
    </div>
  </div>
  <form *ngIf="form && !isLoading" [formGroup]="form" (ngSubmit)="submitForm()">
    <p>
      You are about to delete the creditor {{ deleteCreditor.name }}. All cases debts of these creditor will be
      reassigned to the new creditor
    </p>
    <p>Creditor has {{ deleteCreditor.case_creditors_count }} secured, unsecured or claim debts</p>
    <p>Creditor has {{ deleteCreditor.case_assets_count }} bank accounts</p>
    <!-- Reassignment Creditor ID -->
    <app-input type="select" formControlName="reassign_creditor_id" appearance="outline"
               [label]="'CREDITORS.reassign-title' | translate" [fullWidth]="true" [selectOptions]="creditors"
               [selectLabel]="['id', 'name']" [selectValue]="'id'" [searchable]="true">
    </app-input>
    <!-- Notes -->
    <app-input type="textarea" formControlName="case_creditor_notes" appearance="outline" class="pt-3"
               [label]="'CASES.single.note' | translate" [fullWidth]="true" [selectOptions]="creditors"
               [textAreaCols]="60" [textAreaRows]="8">
    </app-input>
    <!-- Warning Info -->
    <ng-container *ngIf="deleteCreditor">
      <div class="alert alert-warning mt-3">
        <h3><strong>{{ "SHARED.warning" | translate }}!</strong> {{ "CREDITORS.revert-action" | translate }}</h3>
        {{ "CREDITORS.delete-action-first" | translate }}
        <strong class="text-danger">{{ deleteCreditor.name }} #{{ deleteCreditor.id }}</strong>
        {{ "CREDITORS.delete-action-second" | translate }}
        <strong class="text-danger">{{ reassignmentCreditor?.name }} #{{ reassignmentCreditor?.id }}</strong>
      </div>
    </ng-container>
    <!-- Actions -->
    <div class='pt-2 text-right'>
      <button type="button" mat-button color="primary" class="mt-3 ml-2"
              (click)="dialogRef.close()">{{ "SHARED.close" | translate }}
      </button>
      <button *ngIf="authUser" [disabled]="![1, 22, 27, 36, 275019, 273357].includes(authUser?.id)" type="submit"
              mat-raised-button color="primary" class="mt-3">
        {{ "CREDITORS.delete-and-reassign" | translate }}
      </button>
    </div>
  </form>
</div>
