<div class="d-flex flex-column h-100">
    <h2>Add Legal Fee</h2>
  
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="formActive">
        <div class="form-group">
            <div class="row">
                <!-- Claim Reference -->
                <div class="col-3">
                    <app-input type="select" formControlName="case_creditor_claim_ref"
                      appearance="standard" [searchable]="true"
                      label="Case Reference" 
                      [selectOptions]="caseCreditors" [selectLabel]="['reference_number']" [selectValue]="'id'"
                      class="full-width" [showClear]="false" [extraLabel]="false" [fullWidth]="true" [showLabel]="true">
                    </app-input>
                </div>
                <!-- Legal Fee -->
                <div class="col-3">
                    <app-input type="number" formControlName="legal_fee"
                      appearance="standard" label="Legal Fee" class="full-width" [showLabel]="true">
                    </app-input>
                </div>
            </div>
            <div class="row" *ngIf="errorMessage">
              <div class="error">
                {{ errorMessage }}
              </div>
            </div>
        </div>
    </form>
  
    <div class="text-right mt-auto mb-5 d-flex justify-content-end align-items-center">
  
      <button type="button" mat-stroked-button color="primary" class="mr-3"
              (click)="closeDialog()" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
      <button *ngIf="!isLoading" mat-raised-button color="primary" (click)="submit()">
        {{"SHARED.submit" | translate}}
      </button>
      <mat-spinner *ngIf="isLoading" diameter="20" color="accent"></mat-spinner>
    </div>
  </div>
  