import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { Affiliate } from '../../../../../../../_base-shared/models/Affiliate';
import { CaseListFilter } from '../../../../../../../_base-shared/models/Case/CaseListFilter';
import { DepartmentCategory } from '../../../../../../../_base-shared/models/Department/DepartmentCategory';
import { DistributionBatch } from '../../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { Court } from '../../../../../../../_base-shared/models/Entity/Court';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { EntityAdministrator } from '../../../../../../../_base-shared/models/Entity/EntityAdministrator';
import { Notary } from '../../../../../../../_base-shared/models/Entity/Notary';
import { Solicitor } from '../../../../../../../_base-shared/models/Entity/Solicitor';
import { Packager } from '../../../../../../../_base-shared/models/Packager/Packager';
import { CaseCreditorProduct } from '../../../../../../../_base-shared/models/Product/CaseCreditorProduct';
import { CallStatus } from '../../../../../../../_base-shared/models/Status/CallStatus';
import { PackagerStatus } from '../../../../../../../_base-shared/models/Status/PackagerStatus';
import { PaymentStatus } from '../../../../../../../_base-shared/models/Status/PaymentStatus';
import { Status } from '../../../../../../../_base-shared/models/Status/Status';
import { StatusCategory } from '../../../../../../../_base-shared/models/Status/StatusCategory';
import { Team } from '../../../../../../../_base-shared/models/User/Team';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { PackagerService } from '../../../../packager/packager.service';
import { AdministratorsService } from '../../../address-book/administrators/administrators.service';
import { CourtService } from '../../../address-book/court/court.service';
import { NotariesService } from '../../../address-book/notaries/notaries.service';
import { SolicitorsService } from '../../../address-book/solicitors/solicitors.service';
import { AdminPackagerService } from '../../../admin-packager/admin-packager.service';
import { AffiliateService } from '../../../affiliate/affiliate.service';
import { CallStatusService } from '../../../call-status/call-status.service';
import { CreditorService } from '../../../creditor/creditor.service';
import { DepartmentService } from '../../../department/department.service';
import { DistributionBatchService } from '../../../distribution/distribution-batch.service';
import { PaymentStatusService } from '../../../payment-status/payment-status.service';
import { StatusPickerTrait } from '../../../status/status-picker.trait';
import { StatusService } from '../../../status/status.service';
import { TeamService } from '../../../team/team.service';
import { UserService } from '../../../user/user.service';
import { CaseCreditorService } from '../../case-creditor.service';

@Component({
  selector: 'app-case-list-filters',
  templateUrl: './case-list-filters.component.html',
  styleUrls: ['./case-list-filters.component.scss'],
})
export class CaseListFiltersComponent extends StatusPickerTrait implements OnInit, OnDestroy {
  @Input() onlyRelated: boolean                                           = null;
  @Input() type: 'case' | 'legal' | 'customer_contact' | 'affiliate_case' = null;
  @Input() newPartner: 0 | 1                                              = 0;
  @Output() filtersReady                                                  = new EventEmitter<boolean>();
  @Output() submitFilters                                                 = new EventEmitter<CaseListFilter>();

  public isLoading                                 = 0;
  public form: UntypedFormGroup;
  public statusFormControlName                     = 'statuses';
  public dateRadioControl: UntypedFormControl      = new UntypedFormControl();
  public statusCategoryControl: UntypedFormControl = new UntypedFormControl([]);
  public statusControl: UntypedFormControl         = new UntypedFormControl([]);

  public caseListFilter: CaseListFilter;
  public users: Array<User>                               = [];
  public caseCreditorProducts: Array<CaseCreditorProduct> = [];
  public affiliates: Array<Affiliate>                     = [];
  public creditors: Array<Creditor>                       = [];
  public allStatuses: Array<Status>                       = [];
  public statusCategories: Array<StatusCategory>          = [];
  public filteredStatusCategories: Array<StatusCategory>  = [];
  public paymentStatuses: Array<PaymentStatus>            = [];
  public callStatuses: Array<CallStatus>                  = [];
  public notaries: Array<Notary>                          = [];
  public administrators: Array<EntityAdministrator>       = [];
  public courts: Array<Court>                             = [];
  public solicitors: Array<Solicitor>                     = [];
  public teams: Array<Team>                               = [];
  public departmentCategories: Array<DepartmentCategory>  = [];
  public notificationChannels: Array<AppSelectOption>     = [];
  public distributionBatches: Array<DistributionBatch>    = [];
  public hasDistributionOptions: Array<AppSelectOption>   = [];
  public affiliateUsers: Array<User>                      = [];
  public debtStatusOptions: Array<any>                    = [];
  public packagers: Array<Packager>                       = [];
  public packagerStatuses: Array<PackagerStatus>          = [];

  private formChangeSubscriber: Subscription;
  private localStorageName: string;
  public authUser: User;

  constructor(private fb: UntypedFormBuilder,
              private globalEventsService: MainGlobalEventService,
              private translate: TranslateService,
              private statusService: StatusService,
              private creditorService: CreditorService,
              private userService: UserService,
              private notaryService: NotariesService,
              private administratorsService: AdministratorsService,
              private courtService: CourtService,
              private solicitorsService: SolicitorsService,
              private paymentStatusService: PaymentStatusService,
              private affiliateService: AffiliateService,
              private departmentService: DepartmentService,
              private distributionBatchService: DistributionBatchService,
              private teamService: TeamService,
              private callStatusService: CallStatusService,
              private packagerService: AdminPackagerService,
              private generalPackagerService: PackagerService,
              private caseCreditorService: CaseCreditorService) {
    super('statuses', true, true);
  }

  ngOnInit(): void {
    if (this.type === 'legal') {
      this.localStorageName = 'case-legal-list-filters_v5.0.0';
    } else if (this.type === 'customer_contact') {
      this.localStorageName = 'case-customer_contact-list-filters_v5.0.0';
    } else if (this.type === 'affiliate_case') {
      this.localStorageName = 'affiliate-case-list-filters_v5.0.0';
    } else {
      this.localStorageName = 'case-list-filters_v5.0.0';
    }
    this.getDebtStatusOptions();
    // todo: get filters from query string
    this.caseListFilter              = this.getFiltersFromStorage();
    this.caseListFilter.only_related = this.onlyRelated !== null ?
      this.onlyRelated :
      this.caseListFilter.only_related;
    this.filtersReady.emit(false);
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (this.caseListFilter.only_related) {
        this.caseListFilter.user_department_assignments = this.authUser.department_assignments.map(
          departmentAssignment => departmentAssignment.department_id,
        );
      }
      this.buildForm(this.caseListFilter, this.authUser);
    }));

    this.fetchDepartmentCategories();
    this.fetchCaseCreditorProducts();
    this.fetchAffiliates();
    this.fetchCreditors();
    this.fetchStatuses();
    this.fetchPaymentStatuses();
    this.fetchNotaries();
    this.fetchAdministrators();
    this.fetchCourt();
    this.fetchSolicitors();
    this.fetchDistributionBatches();
    this.fetchTeams();
    this.buildSelectOptions();
    this.fetchCallStatuses();
    this.getAffiliateUsers();
    this.fetchPackagers();
    this.fetchPackagerStatuses();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.formChangeSubscriber.unsubscribe();
  }

  private getFiltersFromStorage(): CaseListFilter {
    const filter                       = new CaseListFilter();
    let data                           = JSON.parse(localStorage.getItem(this.localStorageName));
    data                               = data ? data : {};
    const activity_status              = data.activity_status !== undefined ? data.activity_status :
      this.type === 'customer_contact' ? 'active' : 'all';
    filter.search                      = data.search ? data.search : null;
    filter.activity_status             = activity_status;
    filter.start_date                  = data.start_date ? new Date(data.start_date) : null;
    filter.end_date                    = data.end_date ? new Date(data.end_date) : null;
    filter.status_date_type            = data.status_date_type ? data.status_date_type : 'sign_up';
    filter.amount_paid                 = data.amount_paid ? data.amount_paid : null;
    filter.outstanding_balance         = data.outstanding_balance ? data.outstanding_balance : null;
    filter.days_in_claim_sent_status   = data.days_in_claim_sent_status ? data.days_in_claim_sent_status : null;
    filter.days_in_status              = data.days_in_status ? data.days_in_status : null;
    filter.has_property                = data.has_property !== undefined ? data.has_property : 0;
    filter.has_pending_docs            = data.has_pending_docs !== undefined ? data.has_pending_docs : 0;
    filter.has_public_debt             = data.has_public_debt !== undefined ? data.has_public_debt : 0;
    filter.region                      = data.region ? data.region : null;
    filter.city                        = data.city ? data.city : null;
    filter.case_distribution_status    = data.case_distribution_status ? data.case_distribution_status : null;
    filter.distribution_batch_statuses = data.distribution_batch_statuses ? data.distribution_batch_statuses : null;
    filter.distribution_batch_ids      = data.distribution_batch_ids ? data.distribution_batch_ids : null;
    filter.select_all                  = data.select_all !== undefined ? data.select_all : 0;
    filter.only_related                = data.only_related !== undefined ? data.only_related : 0;
    // Case General Relations
    filter.case_creditor_products      = data.case_creditor_products ? data.case_creditor_products : [];
    filter.affiliates                  = data.affiliates ? data.affiliates : [];
    filter.statuses                    = data.statuses ? data.statuses : [];
    filter.payment_statuses            = data.payment_statuses ? data.payment_statuses : [];
    filter.call_statuses               = data.call_statuses ? data.call_statuses : [];
    filter.creditors                   = data.creditors ? data.creditors : [];
    filter.case_creditor_statuses      = data.case_creditor_statuses ? data.case_creditor_statuses : [];
    // Case Roles
    filter.user_department_assignments = data.user_department_assignments ? data.user_department_assignments : [];
    filter.verifier                    = data.verifier ? data.verifier : [];
    filter['legal-advisor']            = data['legal-advisor'] ? data['legal-advisor'] : [];
    filter['case-manager']             = data['case-manager'] ? data['case-manager'] : [];
    filter['customer-contact']         = data['customer-contact'] ? data['customer-contact'] : [];
    filter['creditor-negotiator']      = data['creditor-negotiator'] ? data['creditor-negotiator'] : [];
    filter['notary-manager']           = data['notary-manager'] ? data['notary-manager'] : [];
    filter.lawyer                      = data.lawyer ? data.lawyer : [];
    filter['draft-manager']            = data['draft-manager'] ? data['draft-manager'] : [];
    filter['legal-admin']              = data['legal-admin'] ? data['legal-admin'] : [];
    // Case Entities
    filter.notaries                    = data.notaries ? data.notaries : [];
    filter.administrators              = data.administrators ? data.administrators : [];
    filter.courts                      = data.courts ? data.courts : [];
    filter.solicitors                  = data.solicitors ? data.solicitors : [];
    filter.notary_appointed            = data.notary_appointed !== undefined ? data.notary_appointed : null;
    // Extra
    filter.days_no_contact             = data.days_no_contact ? data.days_no_contact : null;
    filter.days_no_contact_channels    = data.days_no_contact_channels ? data.days_no_contact_channels : [];
    filter.product_group               = data.product_group ? data.product_group : [];
    filter.packagers                   = data.packagers ? data.packagers : [];
    filter.packager_statuses           = data.packager_statuses ? data.packager_statuses : [];
    filter.product_ids                 = data.product_ids ? data.product_ids : [];
    filter.affiliate_user_ids          = data.affiliate_user_ids ? data.affiliate_user_ids : [];

    if (this.type === 'case' || this.type === 'affiliate_case') {
      filter.page_type = this.type;
    }

    return filter;
  }

  private buildForm(caseListFilter: CaseListFilter, authUser: User): void {
    if (this.formChangeSubscriber) {
      this.formChangeSubscriber.unsubscribe();
    }

    this.form = this.fb.group({
      search: [caseListFilter.search],
      start_date: [caseListFilter.start_date],
      end_date: [caseListFilter.end_date],
      activity_status: [caseListFilter.activity_status],
      status_date_type: [caseListFilter.status_date_type],
      amount_paid: [caseListFilter.amount_paid],
      outstanding_balance: [caseListFilter.outstanding_balance],
      days_in_claim_sent_status: [caseListFilter.days_in_claim_sent_status],
      days_in_status: [caseListFilter.days_in_status],
      has_property: [caseListFilter.has_property],
      has_pending_docs: [caseListFilter.has_pending_docs],
      has_public_debt: [caseListFilter.has_public_debt],
      region: [caseListFilter.region],
      city: [caseListFilter.city],
      case_creditor_products: [caseListFilter.case_creditor_products],
      affiliates: [caseListFilter.affiliates],
      creditors: [caseListFilter.creditors],
      statuses: [caseListFilter.statuses],
      payment_statuses: [caseListFilter.payment_statuses],
      call_statuses: [caseListFilter.call_statuses],
      case_creditor_statuses: [caseListFilter.case_creditor_statuses],
      // Distribution
      case_distribution_status: [caseListFilter.case_distribution_status],
      distribution_batch_statuses: [caseListFilter.distribution_batch_statuses],
      distribution_batch_ids: [caseListFilter.distribution_batch_ids],
      select_all: [caseListFilter.select_all],
      only_related: [caseListFilter.only_related],
      // Case Roles
      user_department_assignments: [caseListFilter.user_department_assignments],
      verifier: [caseListFilter.verifier],
      'legal-advisor': [caseListFilter['legal-advisor']],
      'case-manager': [caseListFilter['case-manager']],
      'customer-contact': [caseListFilter['customer-contact']],
      'creditor-negotiator': [caseListFilter['creditor-negotiator']],
      'notary-manager': [caseListFilter['notary-manager']],
      lawyer: [caseListFilter.lawyer],
      'draft-manager': [caseListFilter['draft-manager']],
      'collections-agent': [caseListFilter['collections-agent']],
      'legal-admin': [caseListFilter['legal-admin']],
      // Case Entities
      notaries: [caseListFilter.notaries],
      administrators: [caseListFilter.administrators],
      courts: [caseListFilter.courts],
      solicitors: [caseListFilter.solicitors],
      notary_appointed: [caseListFilter.notary_appointed],
      // Extra
      days_no_contact: [caseListFilter.days_no_contact],
      days_no_contact_channels: [caseListFilter.days_no_contact_channels],
      page_type: [this.type === 'case' || this.type === 'affiliate_case' ? this.type : ''],
      product_group: [caseListFilter.product_group],
      packagers: [caseListFilter.packagers],
      packager_statuses: [caseListFilter.packager_statuses],
      product_ids: [caseListFilter.product_ids],
      affiliate_user_ids: [caseListFilter.affiliate_user_ids],
    });

    this.filtersReady.emit(true);
    this.submitFilters.emit(this.caseListFilter);
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges() {
    this.formChangeSubscriber = this.form.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(res => {
      if (this.form.invalid) {
        return;
      }
      this.caseListFilter = this.form.value;
      // TODO: update filters in query string
      this.storeFiltersToStorage(this.caseListFilter);
      this.submitFilters.emit(this.caseListFilter);
    });
  }

  private fetchNotaries() {
    this.notaryService.index({select_all: 1}).subscribe(next => {
      this.notaries = next.data;
    });
  }

  private fetchAdministrators() {
    this.administratorsService.index({select_all: 1}).subscribe(next => {
      this.administrators = next.data;
    });
  }

  private fetchCourt() {
    this.courtService.index({select_all: 1}).subscribe(next => {
      this.courts = next.data;
    });
  }

  private fetchSolicitors() {
    this.solicitorsService.index({select_all: 1}).subscribe(next => {
      this.solicitors = next.data;
    });
  }

  private fetchDepartmentCategories(): void {
    this.subscriptions.push(
      this.departmentService.categoryIndex(['departments.users']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          console.log(result.data);
          this.departmentCategories =  result.data.map(category => {
            if (category.type === 'drafting-and-judical') {
              category.departments = category.departments.filter(department => department.type !== 'notary-manager');
            }
            return category;
          });
        }),
    );
  }

  private fetchCaseCreditorProducts(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseCreditorService.indexProducts({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.caseCreditorProducts = result.data),
    );
  }

  private fetchAffiliates(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.index({all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.affiliates = result.data),
    );
  }

  private fetchCreditors(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.creditorService.index({all: 1, active: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.creditors = result.data),
    );
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
            this.statusCategories         = result.data;
            this.filteredStatusCategories = result.data;
            this.statusCategories.forEach(category => {
              this.allStatuses.push(category);
              category.statuses.forEach(status => this.allStatuses.push(status));
            });
            this.setStatusControls(this.form.get(this.statusFormControlName).value);
          },
        ),
    );
  }

  private fetchPaymentStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.paymentStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.paymentStatuses = result.data),
    );
  }

  private fetchCallStatuses() {
    this.isLoading++;
    this.subscriptions.push(this.callStatusService.index().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.callStatuses = result.data;
      }),
    );
  }

  private fetchDistributionBatches() {
    this.isLoading++;
    this.subscriptions.push(
      this.distributionBatchService.index().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.distributionBatches = result.data),
    );
  }

  private fetchTeams() {
    this.isLoading++;
    this.subscriptions.push(
      this.teamService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.teams = result.data),
    );
  }

  private buildSelectOptions(): void {
    this.notificationChannels = [
      {label: 'Email', value: 'email'},
      {label: 'SMS', value: 'sms'},
      {label: 'Call', value: 'call'},
      {label: 'WhatsApp', value: 'whatsapp'},
    ];

    this.hasDistributionOptions = [
      {label: '/', value: null},
      {label: 'Yes', value: 'in_distribution'},
      {label: 'No', value: 'not_in_distribution_and_viable'},
      {label: 'Non-viable', value: 'nonviable'},
    ];
  }

  public clearFilters(): void {
    localStorage.removeItem(this.localStorageName);
    this.caseListFilter = this.getFiltersFromStorage();
    this.filtersReady.emit(false);
    this.buildForm(this.caseListFilter, this.authUser);
    this.dateRadioControl.patchValue(null);
    this.statusCategoryControl.patchValue([]);
    this.statusControl.patchValue([]);
  }

  public patchFilter(name: string, value, options = {}): void {
    this.form.get(name).patchValue(value, options);
  }

  private storeFiltersToStorage(filter: CaseListFilter): void {
    return localStorage.setItem(this.localStorageName, JSON.stringify(filter));
  }

  public clearFormControl($event, name) {
    $event.preventDefault();
    $event.stopPropagation();
    this.form.get(name).patchValue(null);
  }

  public clearMultiSelect($event, name: string) {
    $event.stopPropagation();
    this.form.get(name).patchValue([]);
  }

  public dateModifierChange($event) {
    this.form.get('start_date').setValue(DateTime.local().startOf($event.value).toJSDate());
    this.form.get('end_date').setValue(DateTime.local().endOf($event.value).toJSDate());
  }

  public onlyRelatedChange($event: any) {
    const myDepartmentAssignments = $event ?
      this.authUser.department_assignments.map(departmentAssignment => departmentAssignment.department_id) :
      [];
    this.form.get('user_department_assignments').patchValue(myDepartmentAssignments);
    if ($event) {
      this.form.get('user_department_assignments').disable({onlySelf: true, emitEvent: false});
    } else {
      this.form.get('user_department_assignments').enable({onlySelf: true, emitEvent: false});
    }
  }

  public dateChanged($event: MatDatepickerInputEvent<any>, formControlName, endDay = false) {
    if (endDay) {
      const inputValue = $event.value.toString();
      const dt         = DateTime.fromJSDate(new Date(inputValue));
      const endOfDay   = dt.endOf('day');
      const date       = endOfDay.toJSDate();
      this.form.get(formControlName).patchValue(date);
    }
  }

  public selectStatuses(active: boolean) {
    const selectedStatusIds = [];
    this.allStatuses.forEach(status => {
      if (((active && status.flag_case_active) || (!active && !status.flag_case_active))) {
        selectedStatusIds.push(status.id);
      }
    });
    this.setStatusControls(selectedStatusIds);
    this.form.get(this.statusFormControlName).updateValueAndValidity();
  }

  public userIsAMami(): boolean {
    return environment.DISTRIBUTION_USER_IDS.includes(this.authUser.id);
  }

  private getAffiliateUsers(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.getAffiliateUsers({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.affiliateUsers = result.data,
        error => console.log(error),
      ),
    );
  }

  private fetchPackagers(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.packagerService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(
          result => this.packagers = result.data,
        ),
    );
  }

  private fetchPackagerStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.generalPackagerService.getPackagerStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(
          result => {
            this.packagerStatuses = result.data;
          },
        ),
    );
  }

  public getDebtStatusOptions(): void {
    this.caseCreditorService.getCaseCreditorStatuses({select_all: 1})
      .pipe(finalize(() => {
        this.isLoading--;
      }))
      .subscribe(
        res => {
          this.debtStatusOptions = res.data;
        });
  }

  public productGroupUpdated(productGroupSlugs: Array<string>): void {
    const productIds = [];
    productGroupSlugs.forEach(groupSlug => {
      this.caseCreditorProducts.forEach(product => {
        if (product.group_slug === groupSlug) {
          productIds.push(product.id);
        }
      });
    });
    this.form.get('product_ids').patchValue(productIds);
  }
}