import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { MiscConfigService } from './misc-config.service';

@Component({
  selector:    'app-misc-config',
  templateUrl: './misc-config.component.html',
  styleUrls:   ['./misc-config.component.scss'],
})
export class MiscConfigComponent implements OnInit {
  public form: UntypedFormGroup;
  public formReady    = false;
  public isSubmitting = 0;

  constructor(private fb: UntypedFormBuilder,
              private configService: MiscConfigService,
              private toast: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.getConfigData();
  }

  private getConfigData() {
    this.configService.getConfigData().subscribe(next => {
      this.buildForm(next.data);
    });
  }

  public submit() {
    this.isSubmitting++;
    this.configService.index(this.form.value)
      .pipe(finalize(() => this.isSubmitting--))
      .subscribe(
        res => {
          this.toast.success(this.translate.instant('CONFIG.system-event.config-saved'));
        },
        error => {
          this.toast.error(this.translate.instant('CONFIG.system-event.config-saved-error'));
        });
  }

  private buildForm(data) {
    const claimWinPercent = data.find(obj => obj.key === 'claim_win_percent');
    this.form             = this.fb.group({
      claim_win_percent: [claimWinPercent ? claimWinPercent.value : 0, [Validators.min(0), Validators.max(100)]],
    });

    this.formReady = true;
  }
}
