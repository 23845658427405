<div class="d-flex flex-column h-100">
    <h2>{{ "CASES.single.creditors.actions.win_amount.title" | translate }}</h2>
  
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="formActive">
        <div class="form-group">
            <div class="row">
                <!-- Claim Reference -->
                <div class="col-md-6 col-md-offset-2">
                    <app-input type="select" formControlName="case_creditor_claim_ref"
                      appearance="standard" [searchable]="true"
                      [label]='"CASES.single.creditors.actions.win_amount.case_reference" | translate'
                      [selectOptions]="caseCreditors" [selectLabel]="['reference_number']" [selectValue]="'id'"
                      [fullWidth]="false" [showClear]="false" [extraLabel]="false" [showLabel]="true">
                    </app-input>
                </div>
                <!-- Win Amount -->
                <div class="col-md-6 col-md-offset-2">
                    <app-input type="number" formControlName="win_amount"
                      appearance="standard" [label]='"CASES.single.creditors.actions.win_amount.win_amount" | translate' [fullWidth]="false" [showClear]="false" [showLabel]="true">
                    </app-input>
                </div>
            </div>
            <div class="row">
              <!-- Win Percent -->
              <div class="col-md-6 col-md-offset-2">
                  <app-input type="number" formControlName="win_percent"
                    appearance="standard" [label]='"CASES.single.creditors.actions.win_amount.win_percent" | translate' [fullWidth]="false" [showClear]="false" [showLabel]="true">
                  </app-input>
              </div>
              <!-- Claim Fee -->
              <div class="col-md-6 col-md-offset-2">
                  <app-input type="number" formControlName="claim_fee" [readonly]="true"
                    appearance="standard" [label]='"CASES.single.creditors.actions.win_amount.claim_fee" | translate' [fullWidth]="false" [showClear]="false" [showLabel]="true">
                  </app-input>
              </div>
            </div>
        </div>
    </form>
  
    <div class="text-right mt-auto mb-5 d-flex justify-content-end align-items-center">
  
      <button type="button" mat-stroked-button color="primary" class="mr-3"
              (click)="closeDialog()" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
      <button *ngIf="!isLoading" mat-raised-button color="primary" (click)="submit()">
        {{"SHARED.submit" | translate}}
      </button>
      <mat-spinner *ngIf="isLoading" diameter="20" color="accent"></mat-spinner>
    </div>
  </div>
  