import { CaseCreditor } from '../Case/CaseCreditor';

export class CaseCreditorStatus {
  id?: number;
  uuid?: string;

  name: string;
  name_es: string;
  name_en: string;
  for_packager: 0 | 1 | boolean;
  editable?: boolean | 0 | 1;
  removable?: boolean | 0 | 1;
  active?: boolean | 0 | 1;

  created_at?: string | Date;
  updated_at?: string | Date;

  case_creditors?: Array<CaseCreditor>;
}
