<div *ngIf="case" class="card shadow card-height detail-documents mb-4">
  <div class="card-header py-4">
    <div class="d-flex">
      <h1>{{ "CASES.single.documents.heading" | translate }}</h1>
    </div>
    <div class="d-flex get-files-btns">
      <a mat-raised-button color="primary" [disabled]="!contractPdfLocation" target="_blank"
         href="{{storageUrl + contractPdfLocation}}"
         [title]="!contractPdfLocation ?
          ('CASES.single.get-contract-button-disabled' | translate) :
           ('DOCUMENTS.get-contract' | translate)">
        {{ "DOCUMENTS.get-contract" | translate }}
      </a>
      <!--Get mandate button for single case-->
      <a mat-raised-button color="primary" class="ml-3" *ngIf="!case.joint_application"
         [disabled]="!mandatePdfLocation" target="_blank" href="{{storageUrl + mandatePdfLocation}}"
         [title]="!mandatePdfLocation ?
          ('CASES.single.mandates-button-disabled' | translate) :
           ('CASES.single.get-mandates' | translate)">
        {{ "CASES.single.get-mandates" | translate }}
      </a>
      <!--   Get mandate button for joint case   -->
      <a mat-raised-button color="primary" class="ml-3" *ngIf="case.joint_application"
         [disabled]="!mandatePdfLocation" target="_blank" href="{{storageUrl + mandatePdfLocation}}"
         [title]="!mandatePdfLocation ?
          ('CASES.single.mandates-button-disabled' | translate) :
           ('CASES.single.get-client-1-mandates' | translate)">
        {{ "CASES.single.get-client-1-mandates" | translate }}
      </a>
      <a mat-raised-button color="primary" class="ml-3" *ngIf="case.joint_application"
         [disabled]="!mandatePdfLocationPartner" target="_blank" href="{{storageUrl + mandatePdfLocationPartner}}"
         [title]="!mandatePdfLocationPartner ?
          ('CASES.single.mandates-button-disabled' | translate) :
           ('CASES.single.get-client-2-mandates' | translate)">
        {{ "CASES.single.get-client-2-mandates" | translate }}
      </a>
      <button mat-raised-button color="primary" class="ml-3" (click)="sendSignedContract()">
        {{ 'CASES.single.send-signed-contract' | translate }}
      </button>
      <div *ngIf="authUser.role_id === 5 || authUser.role_id === 1 || authUser.role_id === 2"
           class="d-flex justify-content-center flex-wrap" style="min-width: 245px; margin-left: auto">
        <div *ngIf="authUser.id === 27 || authUser.id === 36 || authUser.id === 3 || authUser.id === 6 || authUser.id === 967 || authUser.id === 235517
                  || authUser.id === 582 || authUser.id === 513023 || authUser.id === 34 || authUser.id === 26 || authUser.role_id === 2"
          class="dropleft d-flex">
          <button [disabled]="!contractPdfLocation" mat-raised-button color="primary" class="btn" [matMenuTriggerFor]="resignContractMenu"
                  type="button" [title]="!contractPdfLocation ? ('DOCUMENTS.resign-contract-disabled' | translate) :
                   ('DOCUMENTS.resign-contract' | translate)">
            <mat-icon style="">chevron_left</mat-icon>
            {{ "DOCUMENTS.resign-contract" | translate }}
          </button>
          <mat-menu #resignContractMenu="matMenu" class="resignContractMenu" xPosition="after">
            <a mat-menu-item (click)="resignContract($event, 'email', 'client')" href="#"> Client Email</a>
            <a mat-menu-item (click)="resignContract($event, 'sms', 'client')" href="#"> Client SMS</a>
            <a mat-menu-item (click)="resignContract($event, 'all', 'client')" href="#"> Client Email & SMS</a>
            <a *ngIf="case.joint_application"
               mat-menu-item (click)="resignContract($event, 'email', 'partner')" href="#"> Client 2 Email</a>
            <a *ngIf="case.joint_application"
               mat-menu-item (click)="resignContract($event, 'sms', 'partner')" href="#"> Client 2 SMS</a>
            <a *ngIf="case.joint_application"
               mat-menu-item (click)="resignContract($event, 'all', 'partner')" href="#"> Client 2 Email &
              SMS</a>
          </mat-menu>
        </div>
        <button type="button" [disabled]="!contractPdfLocation || regeneratingContract" class="ml-3"
                mat-raised-button color="primary" (click)="regenerateContract()"
                *ngIf="authUser.id === 1 || authUser.id === 22 || authUser.id === 27 || authUser.id === 36 || authUser.id === 2497"
                [title]="!contractPdfLocation ? ('DOCUMENTS.regenerate-contract-disabled' | translate) : ('DOCUMENTS.regenerate-contract' | translate)">
          {{ "CASES.single.regenerate-contract" | translate }}
        </button>
        <mat-spinner *ngIf="regeneratingContract" diameter="30" class="m-0 m-auto"></mat-spinner>
        <button type="button" *ngIf="case.client" mat-raised-button color="primary" class="ml-3"
                (click)="verifySignature(case.client)">
          <span *ngIf="!case.client.signature_verified_at">
            {{ 'CASES.details.signature-verify' | translate }}
          </span>
          <span *ngIf="case.client.signature_verified_at">
            {{ ('CASES.details.view-signature' | translate) }}
          </span>
        </button>
      </div>
    </div>
    <!-- <button [matMenuTriggerFor]="debtCancellationRequestMenu" mat-raised-button color="primary" class="ml-2 mt-2">
      {{ 'CASES.single.documents.debt_cancellation_request.action' | translate }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #debtCancellationRequestMenu="matMenu">
      <a mat-menu-item [routerLink]="['/client/sign-debt-cancellation-request']" target="_blank"
              [queryParams]="{uuid: case.uuid, type: 'debt-cancellation-request', sign: 'client'}">
        {{ 'CASES.single.documents.debt_cancellation_request.preview' | translate }}
      </a>
      <button mat-menu-item (click)="requestDebtCancellationRequestSignature()">
        {{ 'CASES.single.documents.debt_cancellation_request.request_signature.action' | translate }}
      </button>
    </mat-menu> -->
  </div>
  <app-case-document-upload-list *ngIf="case" [case]="case" [caseCreditor]="null"></app-case-document-upload-list>
</div>
