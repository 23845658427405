import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CaseService } from '../../../case.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CreditorService } from '../../../../creditor/creditor.service';
import { CaseCreditor } from 'projects/_base-shared/models/Case/CaseCreditor';
import { MiscConfigService } from '../../../../config/misc-config/misc-config.service';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CasePaymentPlanService } from '../../../case-payment-plan.service';

@Component({
  templateUrl: './legal-fee.component.html',
  styleUrls: ['./legal-fee.component.scss'],
})
export class LegalFeeComponent implements OnInit {
  public form: UntypedFormGroup;
  public formActive = false;
  public isLoading = 0;
  public caseCreditors: CaseCreditor[] = [];
  public errorMessage = null;

  constructor(public dialogRef: MatDialogRef<LegalFeeComponent>,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private toast: ToastrService,
              private translate: TranslateService,
              public translateService: TranslateService,
              private configService: MiscConfigService,
              private caseCreditorService: CaseCreditorService,
              private casePaymentPlanService: CasePaymentPlanService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.data.case.creditors.forEach(creditor => {
      this.caseCreditors.push({
        id: creditor.pivot.id,
        reference_number: creditor.pivot.reference_number,
      });
    });
    console.log(this.data.creditors);
  }

  buildForm() {
    this.form = this.fb.group({
        case_creditor_claim_ref: [null, Validators.required],
        legal_fee: [null, Validators.required],
    });
    // watch for value changes in the creditor field
    this.form.get('case_creditor_claim_ref').valueChanges.subscribe(value => {
      this.form.get('legal_fee').enable();
      this.errorMessage = null;
      // if (value) {
      //   const creditor = this.data.creditors.find(creditor => creditor.id === value);
      //   console.log(creditor);
      //   if(!creditor.win_amount) {
      //     this.errorMessage = 'Error: Case not assigned a win amount.';
      //     this.form.get('legal_fee').setValue(null);
      //     this.form.get('legal_fee').disable();
      //   }
      // }
    });
    
    this.formActive = true;
  }

  closeDialog(value = null) {
    this.dialogRef.close(value);
  }

  submit() {
    if (this.form.valid) {
      this.isLoading++;

      const formData = this.form.getRawValue();

      formData.payment_plan_type_id = 10;
      formData.case_creditor_ids = [formData.case_creditor_claim_ref];
      formData.win_type = this.data.creditors.find(creditor => creditor.id === formData.case_creditor_claim_ref).win_type;
      formData.win_amount = Number(this.data.creditors.find(creditor => creditor.id === formData.case_creditor_claim_ref).win_amount);
      formData.phases = [
        {
          start_from_type: 'date',
          start_from: new Date().toISOString(),
          amount: formData.legal_fee,
          split_amount_type: 'equally',
          installment_amount: formData.legal_fee,
          installments_count: 1,
          interval: null,
          interval_unit: 'month'
        }
      ]

      this.data.creditors.forEach(creditor => {
        if (creditor.id === formData.case_creditor_claim_ref) {
          console.log(creditor);
          if(creditor.legal_fees?.length > 0) {
            formData.legal_fee_id = creditor.legal_fees[0].id;
          }
        }
      });


      this.casePaymentPlanService.storePaymentPlan(this.data.case.id, formData)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
          result => {
            // this.generatedPaymentPlan = result.data;
            this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
              {model: 'Additional payment plan'}));
            this.dialogRef.close({dismissed: false});
          },
          error => {
            // this.serverResponse = error.error;
            this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
              {model: 'Additional payment plan'}));
          },
        );
    } else {
      this.form.markAllAsTouched();
    }
  }

}
