<form *ngIf="form" [formGroup]="form" #ngForm class="filters">
  <div class="row">
    <div class="col-8">
      <!-- Start Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="startDate" formControlName="start_date">
        <button *ngIf="form.get('start_date') && form.get('start_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('start_date').disabled" (click)="clearFormControl($event, 'start_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate color="primary"></mat-datepicker>
      </mat-form-field>
      <!-- End Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="endDate" formControlName="end_date"
               (dateChange)="dateChanged($event, 'end_date', true)">
        <button *ngIf="form.get('end_date') && form.get('end_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('end_date').disabled" (click)="clearFormControl($event, 'end_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate color="primary"></mat-datepicker>
      </mat-form-field>
      <button mat-stroked-button type="button" color="primary" class="btn ml-2"
              (click)="clearDates()">{{"SHARED.reset-date" | translate}}</button>
      <button mat-stroked-button type="button" color="primary" class="btn mt-2 ml-2"
              (click)="clearFilters()">{{"SHARED.reset-filters" | translate}}</button>
      <!-- From/To Shortcut modifiers -->
      <div class="d-inline-block">
        <mat-radio-group [formControl]="dateRadioControl" aria-label="Select an option"
                         (change)="dateModifierChange($event)">
          <mat-radio-button value="day" class="pl-2"
                            style="margin-right: 10px">{{ "CASES.single.today" | translate }}</mat-radio-button>
          <mat-radio-button value="isoWeek"
                            style="margin-right: 10px">{{ "CASES.single.this-week" | translate }}</mat-radio-button>
          <mat-radio-button value="month"
                            style="margin-right: 10px">{{ "CASES.single.this-month" | translate }}</mat-radio-button>
        </mat-radio-group>
        <!-- TODO: implement -->
        <!--<mat-slide-toggle formControlName="only_related" color="primary" class="ml-5"-->
        <!--                  (ngModelChange)="onlyRelatedChange($event)">-->
        <!--  My Case Payments-->
        <!-- <div class="d-inline-block pl-5">
          <mat-label style="padding-right: 10px; display: block">
            {{ 'CASES.list.filters.status_date_type.label' | translate }}
          </mat-label>
          <mat-radio-group formControlName="activity_status_filter_type" aria-label="Select an option">
            <mat-radio-button value="currently" style="margin-right: 10px">
              {{ "PAYMENTS.activity-status.currently" | translate }}
            </mat-radio-button>
            <mat-radio-button value="in_time_frame" style="margin-right: 10px">
              {{ "PAYMENTS.activity-status.in-time-frame" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="d-inline-block pl-5">
          <mat-label style="padding-right: 10px; display: block"
                     [matTooltip]="'CASES.list.filters.activity_status.label-tooltip' | translate">
            {{ 'CASES.list.filters.activity_status.label' | translate }}
          </mat-label>
          <mat-radio-group formControlName="activity_status" aria-label="Select an option">
            <mat-radio-button value="active" style="margin-right: 10px">
              {{ "CASES.list.filters.activity_status.options.active" | translate }}
            </mat-radio-button>
            <mat-radio-button value="inactive" style="margin-right: 10px">
              {{ "CASES.list.filters.activity_status.options.inactive" | translate }}
            </mat-radio-button>
            <mat-radio-button value="all" style="margin-right: 10px">
              {{ "CASES.list.filters.activity_status.options.all" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div> -->
      </div>
    </div>
    <!-- Search -->
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('search').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>

  <div class="advanced-filters pb-5 pt-3">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ "PAYMENTS.advanced-filters" | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-6 col-lg">
            <app-status-filter [parentForm]="form" [controlName]="'statuses'"></app-status-filter>
          </div>
          <!-- Payment Statuses -->
          <div class="col-6 col-lg">
            <app-input type="select" formControlName="payment_statuses" appearance="standard"
                       fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                       [label]="'CASES.single.general.status_editor.payment_status' | translate"
                       [selectOptions]="paymentStatuses" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
          </div>
          <!-- Has Card -->
          <div class="col-6 col-lg">
            <mat-form-field color="primary" appearance="standard" class="input-full-width">
              <mat-label>{{ "CASES.single.card-status" | translate }}</mat-label>
              <mat-select formControlName="card_status">
                <!--<mat-option [value]="null">All</mat-option>-->
                <mat-option [value]="'has_cards'">{{'CASES.single.card-status-card' | translate}}</mat-option>
                <mat-option
                        [value]="'doesnt_have_cards'">{{'CASES.single.card-status-no-card' | translate}}</mat-option>
                <mat-option
                        [value]="'has_multiple_cards'">{{'CASES.single.card-status-multiple-cards' | translate}}</mat-option>
                <mat-option
                        [value]="'has_expired_cards'">{{'CASES.single.card-status-expired-cards' | translate}}</mat-option>
              </mat-select>
              <button *ngIf="form.get('card_status')?.value" mat-button matSuffix mat-icon-button
                      [disabled]="form.get('card_status').disabled" aria-label="Clear"
                      (click)="clearMultiSelect($event, 'card_status')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- Call status-->
          <div class="col-6 col-lg">
            <app-input  type="select" formControlName="call_statuses" appearance="standard"
                        [searchable]="true" [label]="'CONFIG.dialer.call_status' | translate" [fullWidth]="true"
                        class="full-width" [selectOptions]="callStatuses" [selectLabel]="['name']"
                        [selectValue]="'id'" [showClear]="false" [showLabel]="true">
            </app-input>
          </div>
          <!-- Pay Day -->
          <div class="col-6 col-lg">
            <mat-form-field color="primary" appearance="standard" class="input-full-width">
              <mat-label>{{ "PAYMENTS.pay-day" | translate }}</mat-label>
              <mat-select formControlName="pay_day" id="pay_day">
                <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
              </mat-select>
              <button *ngIf="form.get('pay_day')?.value" mat-button matSuffix mat-icon-button aria-label="Clear"
                      [disabled]="form.get('pay_day').disabled" (click)="clearMultiSelect($event, 'pay_day')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <!-- Products -->
          <div class="col-6 col-lg">
            <mat-form-field color="primary" appearance="standard" class="input-full-width">
              <mat-label>{{ "CASES.single.claim-type" | translate }}</mat-label>
              <mat-select formControlName="products" multiple>
                <mat-option *ngFor="let product of products" [value]="product.id">{{ product.name }}</mat-option>
              </mat-select>
              <button *ngIf="form.get('products')?.value" mat-button matSuffix mat-icon-button aria-label="Clear"
                      [disabled]="form.get('products').disabled" (click)="clearMultiSelect($event, 'products')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div *ngIf="userIsAMami()" class="col-6 col-lg">
            <app-input type="select" formControlName="case_distribution_status" appearance="standard" [fullWidth]="true"
                       [label]="'CASES.list.filters.has_distribution.label' | translate" [showClear]="true"
                       [selectOptions]="hasDistributionOptions" [selectLabel]="'label'" [selectValue]="'value'">
            </app-input>
          </div>
          <!-- Batch ID -->
          <div *ngIf="userIsAMami()" class="col-6 col-lg">
            <app-input type="select" formControlName="distribution_batch_ids" appearance="standard" multiple
                       fullWidth="false"
                       [label]="'DISTRIBUTION.batch.selector.distribution_batch_id' | translate"
                       [selectOptions]="distributionBatches" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
          </div>
          <!-- Payment Plan Type -->
          <div class="col-6 col-lg">
            <app-input type="select" formControlName="plan_type_slugs" appearance="standard" multiple fullWidth="false"
                       [label]="'PAYMENTS.list.filters.payment_plan_type.label' | translate"
                       [selectOptions]="paymentPlanTypes" [selectLabel]="'name'" [selectValue]="'slug'">
            </app-input>
          </div>
          <!-- Payment Plan Type -->
          <div class="col-6 col-lg">
            <app-input type="select" formControlName="collections-agent" appearance="standard" multiple fullWidth="false"
                       [label]="'Collections agent'"
                       [selectOptions]="collectionDepartmentUsers" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="d-flex" style="align-items: center">
    <!-- Payment Method -->
    <app-input type="select" formControlName="payment_method" appearance="standard"
               [fullWidth]="false" [label]="'CASES.single.payment-method' | translate"
               (ngModelChange)="paymentMethodChanged($event)" [selectOptions]="paymentMethods"
               [selectLabel]="'name'" [selectValue]="'slug'">
    </app-input>
    <!-- Income Account -->
    <app-input type="select" *ngIf="this.form.get('payment_method').value === 'wire'"
               formControlName="income_account_id" appearance="standard" class="ml-2"
               [fullWidth]="false" [label]="'Bank'" [selectOptions]="incomeBankAccounts"
               [selectLabel]="['bank_name', 'account_number']" [selectValue]="'id'" [showClear]="false">
    </app-input>
    <!-- Paid Status -->
    <div class="d-inline-block pb-3 pl-5">
      <mat-label style="display: block">{{ "PAYMENTS.list.filters.paid_status.label" | translate }}</mat-label>
      <mat-radio-group formControlName="paid_status" aria-label="Select an option">
        <mat-radio-button value="only_unpaid" class="pr-2">
          {{ "PAYMENTS.list.filters.paid_status.options.only_unpaid" | translate }}
        </mat-radio-button>
        <mat-radio-button value="only_paid" class="pr-2">
          {{ "PAYMENTS.list.filters.paid_status.options.only_paid" | translate }}
        </mat-radio-button>
        <mat-radio-button value="all" class="pr-2">
          {{ "PAYMENTS.list.filters.paid_status.options.all" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <!-- Paid Status -->
    <div class="d-inline-block pb-3 pl-5">
      <mat-label style="display: block">{{ "PAYMENTS.list.filters.status.label" | translate }}</mat-label>
      <mat-radio-group formControlName="billable_status" aria-label="Select an option">
        <mat-radio-button value="collections" class="pr-2">
          {{ "PAYMENTS.list.filters.status.options.collections" | translate }}
        </mat-radio-button>
        <mat-radio-button value="retentions" class="pr-2">
          {{ "PAYMENTS.list.filters.status.options.retentions" | translate }}
        </mat-radio-button>
        <mat-radio-button value="all" class="pr-2">
          {{ "PAYMENTS.list.filters.status.options.all" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="userIsAMami()" class="d-inline-block pb-3 pl-5">
      <mat-slide-toggle color="primary" class="mt-3"
                        (change)="toggleDistributionVisibility($event)">
        {{"PAYMENTS.list.boxes.show-distribution" | translate}}
      </mat-slide-toggle>
    </div>
  </div>
</form>
