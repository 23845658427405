import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { DepartmentCategory } from '../../../../../../../../_base-shared/models/Department/DepartmentCategory';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { CaseContract } from 'projects/_base-shared/models/CaseDocument/CaseContract';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CaseCreditorService } from '../../../../case/case-creditor.service';

@Component({
  selector:    'app-case-creditor-contract-editor',
  templateUrl: './case-contract-editor.component.html',
  styles:      [],
})
export class CaseContractEditorComponent implements OnInit {
  @Input() public case: Case;
  @Input() caseCreditor: CaseCreditor;
  @Output() caseContractUpdated: EventEmitter<Array<CaseContract>> = new EventEmitter<Array<CaseContract>>();

  public form: UntypedFormGroup;
  public formActive: boolean;
  public isEditing = false;
  public isLoading = 0;
  public isSubmitting: boolean;
  public departmentCategories: Array<DepartmentCategory>;
  public caseContractUpdate: Array<CaseContract>;
  public authUser: User;
  public serverResponse: LaravelResourceResponse;

  constructor(private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private caseCreditorService: CaseCreditorService
            ) {
  }

  ngOnInit(): void {
    console.log(this.caseCreditor);
    this.buildForm(this.case.id, this.caseContractUpdate);
  }

  private buildForm(caseId, caseContractUpdate: Array<CaseContract>) {
    this.formActive = false;
    this.form       = this.fb.group({});

    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      console.log(this.case);

    this.form = this.fb.group({
        caseCreditor:    this.fb.group({
        loan_start_date: [{value: this.caseCreditor.loan_start_date, disabled: true}, Validators.required],
        contract_number: [{value: this.caseCreditor.contract_number, disabled: true}, Validators.required]
      })
    });
      setTimeout(() => this.formActive = true, 0);
    });
  }

  public toggleEditMode(state: boolean) {
    this.isEditing = state;
    this.toggleFormControls(this.isEditing);
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      this.toastr.error('Invalid Configuration Form');
      return;
    }

    // update case creditor
    this.isSubmitting = true;
    if(this.caseCreditor.loan_start_date !== form.get('caseCreditor.loan_start_date').value) {
        const date = new Date(form.get('caseCreditor.loan_start_date').value);
        date.setDate(date.getDate() + 1);
        this.caseCreditor.loan_start_date = date.toISOString().split('T')[0];
    }
    this.caseCreditor.contract_number = form.get('caseCreditor.contract_number').value;
    this.caseCreditorService.updateCaseCreditor(this.case.id, this.caseCreditor.id, this.caseCreditor).subscribe(
        (response: LaravelResourceResponse) => {
            this.serverResponse = response;
            this.toastr.success('Case Creditor updated successfully');
            this.isSubmitting = false;
            this.isEditing = false;
            this.toggleFormControls(this.isEditing);
        },
        error => {
            this.toastr.error('Error updating Case Creditor');
            this.isSubmitting = false;
        }
        );
  }

  private toggleFormControls(isEnabled: boolean) {
    if (isEnabled) {
      this.form.get('caseCreditor.loan_start_date').enable();
      this.form.get('caseCreditor.contract_number').enable();
    } else {
        this.form.get('caseCreditor.loan_start_date').disable();
        this.form.get('caseCreditor.contract_number').disable();
    }
  }

  public clearFormControl($event, name) {
    $event.preventDefault();
    $event.stopPropagation();
    this.form.get(name).patchValue(null);
  }

}
