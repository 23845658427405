<div class="card shadow h-100">
    <div class="card-header">
      <div class="d-flex justify-content-between align-items-center" style="height: 40px">
        <div>
          <h3>{{ 'CASES.single.case-details' | translate }}</h3>
        </div>
        <div *ngIf="!isEditing" class="col-5 text-right">
          <button mat-icon-button color="primary" (click)="toggleEditMode(true)" class="p-0">
            <mat-icon>settings</mat-icon>
          </button>
        </div>
      </div>
    </div>
  
    <div class="card-body d-flex flex-column">
        <div *ngIf="isLoading" class="pt-4 pl-4">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
      <app-server-response [response]="serverResponse"></app-server-response>
      <form class="disabled-form" *ngIf="formActive" [hidden]="isLoading" [formGroup]="form" (ngSubmit)="submitForm(form)">
            <div formGroupName="caseCreditor">
                <!-- Ref Number -->
                    <!-- <app-input formControlName="loan_start_date" [label]="'CASE_CREDITOR.model.loan_start_date' | translate" matInput 
                            [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                            flexClass="align-items-center">
                    </app-input> -->

                    <div class="row">
                        <div class="col-6" style="line-height: 40px;">{{ 'CASE_CREDITOR.model.loan_start_date' | translate }}</div>
                        <div class="col-6" style="padding-left: 0px !important;">
                            <mat-form-field color="primary" appearance="standard" style="width: 100%;">
                              <!-- <mat-label>{{ "CASE_CREDITOR.model.loan_start_date" | translate }}</mat-label> -->
                              <input matInput [matDatepicker]="startDate" formControlName="loan_start_date">
                              <button *ngIf="form.get('loan_start_date') && form.get('loan_start_date').value" mat-button matSuffix mat-icon-button
                                      [disabled]="form.get('loan_start_date').disabled" (click)="clearFormControl($event, 'start_date')"
                                      aria-label="Clear">
                                <mat-icon>close</mat-icon>
                              </button>
                              <mat-datepicker-toggle *ngIf="isEditing" matSuffix [for]="startDate"></mat-datepicker-toggle>
                              <mat-datepicker #startDate color="primary"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                <!-- Ref Number -->
                    <app-input formControlName="contract_number" [label]="'CASE_CREDITOR.model.contract_number' | translate"
                            [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                            flexClass="align-items-center">
                    </app-input>
            </div>
          <div *ngIf="isEditing" class="col-12 text-right">
            <button mat-button color="primary" class="mr-3" (click)="toggleEditMode(false)">
              {{ 'SHARED.cancel' | translate }}
            </button>
            <app-spinner-btn type="submit" [loading]="isSubmitting" color="primary">
              {{ 'SHARED.save' | translate }}
            </app-spinner-btn>
          </div>
      </form>
    </div>
  </div>