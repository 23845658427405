<div class="container">
 <form *ngIf="formReady" class="row" [formGroup]="form" (ngSubmit)="submit()">
    <div class="col-12">
      <h1>{{"CONFIG.misc.header" | translate}}</h1>
    </div>
    <div class="col-12">
      <div class="card shadow">
        <div class="card-body pt-5">
          <div class="container">
            <div class="row pb-5">
              <!-- LSO -->
              <div class="col-6">
                <app-input type="number" formControlName="claim_win_percent" [fullWidth]="true"
                          [label]="'CONFIG.misc.claim_win_percent' | translate">
                </app-input>
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-8">
                <div class="d-flex">
                  <app-spinner-btn [loading]="isSubmitting" [name]="'SHARED.save' | translate"></app-spinner-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div> 
